import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded shadow-sm p-10 p-lg-15 mx-auto" }
const _hoisted_2 = { class: "mb-10 text-center" }
const _hoisted_3 = { class: "text-gray-400 fw-bold fs-4" }
const _hoisted_4 = { class: "row fv-row mb-7" }
const _hoisted_5 = { class: "col-xl-6" }
const _hoisted_6 = { class: "fv-plugins-message-container" }
const _hoisted_7 = { class: "fv-help-block" }
const _hoisted_8 = { class: "col-xl-6" }
const _hoisted_9 = { class: "fv-plugins-message-container" }
const _hoisted_10 = { class: "fv-help-block" }
const _hoisted_11 = { class: "fv-row mb-7" }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = {
  class: "mb-10 fv-row",
  "data-kt-password-meter": "true"
}
const _hoisted_15 = { class: "mb-1" }
const _hoisted_16 = { class: "position-relative mb-3" }
const _hoisted_17 = { class: "fv-plugins-message-container" }
const _hoisted_18 = { class: "fv-help-block" }
const _hoisted_19 = { class: "fv-row mb-5" }
const _hoisted_20 = { class: "fv-plugins-message-container" }
const _hoisted_21 = { class: "fv-help-block" }
const _hoisted_22 = { class: "fv-row mb-10" }
const _hoisted_23 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_24 = { class: "text-center" }
const _hoisted_25 = {
  id: "kt_sign_up_submit",
  ref: "submitButton",
  type: "submit",
  class: "btn btn-lg btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Wrapper"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Form"),
      _createVNode(_component_Form, {
        class: "form w-100 fv-plugins-bootstrap5 fv-plugins-framework",
        novalidate: "novalidate",
        onSubmit: _ctx.onSubmitRegister,
        id: "kt_login_signup_form",
        "validation-schema": _ctx.registration
      }, {
        default: _withCtx(() => [
          _createCommentVNode("begin::Heading"),
          _createElementVNode("div", _hoisted_2, [
            _createCommentVNode("begin::Title"),
            _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-dark mb-3" }, "Create an Account", -1 /* HOISTED */)),
            _createCommentVNode("end::Title"),
            _createCommentVNode("begin::Link"),
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createTextVNode(" Already have an account? ")),
              _createVNode(_component_router_link, {
                to: "/sign-in",
                class: "link-primary fw-bolder"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Sign in here ")
                ])),
                _: 1 /* STABLE */
              })
            ]),
            _createCommentVNode("end::Link")
          ]),
          _createCommentVNode("end::Heading"),
          _createCommentVNode("begin::Action"),
          _cache[10] || (_cache[10] = _createElementVNode("button", {
            type: "button",
            class: "btn btn-light-primary fw-bolder w-100 mb-10"
          }, [
            _createElementVNode("img", {
              alt: "Logo",
              src: "media/svg/brand-logos/google-icon.svg",
              class: "h-20px me-3"
            }),
            _createTextVNode(" Sign in with Google ")
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Action"),
          _createCommentVNode("begin::Separator"),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex align-items-center mb-10" }, [
            _createElementVNode("div", { class: "border-bottom border-gray-300 mw-50 w-100" }),
            _createElementVNode("span", { class: "fw-bold text-gray-400 fs-7 mx-2" }, "OR"),
            _createElementVNode("div", { class: "border-bottom border-gray-300 mw-50 w-100" })
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Separator"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Col"),
            _createElementVNode("div", _hoisted_5, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { class: "form-label fw-bolder text-dark fs-6" }, "First Name", -1 /* HOISTED */)),
              _createVNode(_component_Field, {
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                placeholder: "",
                name: "first_name",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ErrorMessage, { name: "first_name" })
                ])
              ])
            ]),
            _createCommentVNode("end::Col"),
            _createCommentVNode("begin::Col"),
            _createElementVNode("div", _hoisted_8, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label fw-bolder text-dark fs-6" }, "Last Name", -1 /* HOISTED */)),
              _createVNode(_component_Field, {
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                placeholder: "",
                name: "last_name",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_ErrorMessage, { name: "last_name" })
                ])
              ])
            ]),
            _createCommentVNode("end::Col")
          ]),
          _createCommentVNode("end::Input group"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_11, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label fw-bolder text-dark fs-6" }, "Email", -1 /* HOISTED */)),
            _createVNode(_component_Field, {
              class: "form-control form-control-lg form-control-solid",
              type: "email",
              placeholder: "",
              name: "email",
              autocomplete: "off"
            }),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_ErrorMessage, { name: "email" })
              ])
            ])
          ]),
          _createCommentVNode("end::Input group"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_14, [
            _createCommentVNode("begin::Wrapper"),
            _createElementVNode("div", _hoisted_15, [
              _createCommentVNode("begin::Label"),
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label fw-bolder text-dark fs-6" }, " Password ", -1 /* HOISTED */)),
              _createCommentVNode("end::Label"),
              _createCommentVNode("begin::Input wrapper"),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_Field, {
                  class: "form-control form-control-lg form-control-solid",
                  type: "password",
                  placeholder: "",
                  name: "password",
                  autocomplete: "off"
                }),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_ErrorMessage, { name: "password" })
                  ])
                ])
              ]),
              _createCommentVNode("end::Input wrapper")
            ]),
            _createCommentVNode("end::Wrapper")
          ]),
          _createCommentVNode("end::Input group-"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_19, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label fw-bolder text-dark fs-6" }, "Confirm Password", -1 /* HOISTED */)),
            _createVNode(_component_Field, {
              class: "form-control form-control-lg form-control-solid",
              type: "password",
              placeholder: "",
              name: "password_confirmation",
              autocomplete: "off"
            }),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_ErrorMessage, { name: "password_confirmation" })
              ])
            ])
          ]),
          _createCommentVNode("end::Input group"),
          _createCommentVNode("begin::Input group"),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", _hoisted_23, [
              _createVNode(_component_Field, {
                class: "form-check-input",
                type: "checkbox",
                name: "toc",
                value: "1"
              }),
              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "form-check-label fw-bold text-gray-700 fs-6" }, [
                _createTextVNode(" I Agree & "),
                _createElementVNode("a", {
                  href: "#",
                  class: "ms-1 link-primary"
                }, "Terms and conditions"),
                _createTextVNode(". ")
              ], -1 /* HOISTED */))
            ])
          ]),
          _createCommentVNode("end::Input group"),
          _createCommentVNode("begin::Actions"),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("button", _hoisted_25, _cache[9] || (_cache[9] = [
              _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
              _createElementVNode("span", { class: "indicator-progress" }, [
                _createTextVNode(" Please wait... "),
                _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
              ], -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */)
          ]),
          _createCommentVNode("end::Actions")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
      _createCommentVNode("end::Form")
    ]),
    _createCommentVNode("end::Wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}